<template>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">{{ $t("contactUs") }}</h3>
    </div>
    <div class="card-body">
      <div class="row g-5 mb-5 mb-lg-10">
        <div class="col-sm-6 pe-lg-10">
          <div class="text-center bg-light card-rounded d-flex flex-column justify-content-center p-10 h-100">
            <span class="svg-icon svg-icon-3tx svg-icon-primary">
              <img src="media/svg/social-logos/whatsapp.svg" class="h-45px my-2" alt="">
            </span>
            <h1 class="text-dark fw-bolder my-5">{{ $t("generalEnquiry") }} WhatsApp</h1>
            <div class="text-gray-700 fs-3 fw-bold"><a href="http://wa.me/85269979016" target="_blank">+852-69979016</a></div>
          </div>
        </div>
        <div class="col-sm-6 ps-lg-10">
          <div class="text-center bg-light card-rounded d-flex flex-column justify-content-center p-10 h-100">
            <span class="svg-icon svg-icon-3tx svg-icon-primary">
              <img src="media/svg/brand-logos/facebook-4.svg" class="h-40px my-2" alt="">
            </span>
            <h1 class="text-dark fw-bolder my-5">Facebook Messenger</h1>
            <div class="text-gray-700 fs-3 fw-bold"><a href="http://m.me/RankOpen" target="_blank">http://m.me/RankOpen</a></div>
          </div>
        </div>
      </div>

      <div class="row g-5 mb-5">
        <div class="col-sm-6 pe-lg-10">
          <div class="text-center bg-light card-rounded d-flex flex-column justify-content-center p-10 h-100">
            <span class="svg-icon svg-icon-3tx svg-icon-success">
              <inline-svg src="media/icons/duotune/communication/com007.svg" class="h-50px my-2" alt=""/>
            </span>
            <h1 class="text-dark fw-bolder my-5">{{ $t("emergencyContact") }}</h1>
            <div class="text-gray-700 fs-3 fw-bold"><a href="http://wa.me/85296263602" target="_blank">+852-96263602</a></div>
          </div>
        </div>
        <div class="col-sm-6 ps-lg-10">
          <div class="text-center bg-light card-rounded d-flex flex-column justify-content-center p-10 h-100">
            <span class="svg-icon svg-icon-3tx svg-icon-primary">
              <inline-svg src="media/icons/duotune/finance/fin006.svg" class="h-50px my-2" alt=""/>
            </span>
            <h1 class="text-dark fw-bolder my-5">{{ $t("businessContact") }}</h1>
            <div class="text-gray-700 fs-3 fw-bold"><a href="http://wa.me/85296263602" target="_blank">+852-96263602</a></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card mt-10" v-if="$store.state.uid == 1">
    <div class="card-header">
      <h3 class="card-title">Style Demo</h3>
    </div>
    <div class="card-body">
      <div style="">
          <a href="#" class="btn btn-primary btn-sm me-5" style="width:120px">詳情</a>
          <a href="#" class="btn btn-secondary btn-sm me-5" style="width:120px">簽表</a>
          <a href="#" class="btn btn-danger btn-sm" style="width:120px">報名</a>
        </div>
      
        <div class="mt-5">
          <a href="#" class="btn btn-white me-3">White</a>
          <a href="#" class="btn btn-primary me-3">Primary</a>
          <a href="#" class="btn btn-light me-3">Light</a>
          <a href="#" class="btn btn-light text-danger me-3">Light</a>
          <a href="#" class="btn btn-secondary me-3">Secondary</a>
          <a href="#" class="btn btn-success me-3">Success</a>
          <a href="#" class="btn btn-info me-3">Info</a>
          <a href="#" class="btn btn-warning me-3">Warning</a>
          <a href="#" class="btn btn-danger me-3">Danger</a>
          <a href="#" class="btn btn-dark">Dark</a>
        </div>

        <div class="mt-5">
          <a href="#" class="btn btn-light-primary">primary</a>
          <a href="#" class="btn btn-light-success">success</a>
          <a href="#" class="btn btn-light-info">info</a>
          <a href="#" class="btn btn-light-warning">warning</a>
          <a href="#" class="btn btn-light-danger">danger</a>
          <a href="#" class="btn btn-light-dark">dark</a>
        </div>
        <div class="mt-5">
          <span class="badge badge-white me-3">New</span>
          <span class="badge badge-primary me-3">New</span>
          <span class="badge badge-light me-3">New</span>
          <span class="badge badge-light text-danger me-3">New</span>
          <span class="badge badge-secondary me-3">New</span>
          <span class="badge badge-secondary text-danger me-3">New</span>
          <span class="badge badge-success me-3">New</span>
          <span class="badge badge-info me-3">New</span>
          <span class="badge badge-warning me-3">New</span>
          <span class="badge badge-danger me-3">New</span>
          <span class="badge badge-dark me-3">New</span>
        </div>
        <div class="mt-5">
          <span class="badge badge-light-primary me-3">New</span>
          <span class="badge badge-light-success me-3">New</span>
          <span class="badge badge-light-info me-3">New</span>
          <span class="badge badge-light-warning me-3">New</span>
          <span class="badge badge-light-danger me-3">New</span>
          <span class="badge badge-light-dark me-3">New</span>
        </div>
      
      </div>
    </div>
</template>

<style></style>

<script>
export default {
  name: "contactPage",
};
</script>
